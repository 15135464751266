(() => {

  const container = document.querySelector('.component-thank-you');
  const contentContainer = container.querySelector(".component__content");
  const galleryColumn = container.querySelector(".content__secondary");
  const contentInner = contentContainer.querySelector(".component__content__inner");
  const gallery = container.querySelector('.gallery-thank-you');
  const signatureEl = contentContainer.querySelector(".signatures");
  const signatures =  Array.from(signatureEl.querySelectorAll("img"));

  let active;

  const getGroupCenter = (group) => {
    const rect = group.getBoundingClientRect();
    return rect.height/2;
  }

  const removeActiveClasses = (el) => {
    const classes = el.className.split(" ").filter(c => !c.startsWith('active'));
    el.className = classes.join(" ").trim();
  }

  const createGroup = () => {
    const groupEl = document.createElement("div");
    groupEl.classList.add("group-thank-you");
    return groupEl;
  }

  signatures.forEach(s => s.style.visibility = "hidden");

  const signatureSibling = signatureEl.previousElementSibling;
  const signatureGroup = [signatureSibling,signatureEl];
  signatureSibling.parentNode.removeChild(signatureSibling);
  signatureEl.parentNode.removeChild(signatureEl);

  const letterEls = Array.from(contentContainer.querySelectorAll(".component__content__inner > *:not(h2):not(div)"));
  let letterGroups = [];
  let letterGroup = [];
  for (let e=0;e<letterEls.length;e++) {
    const el = letterEls[e];
    if (el.tagName.toLowerCase() === "h3") {
      if (letterGroup.length) letterGroups.push(letterGroup);
      letterGroup = [el];
    } else {
      letterGroup.push(el);
    }
  }
  letterGroups.push(letterGroup);
  letterGroups.push(signatureGroup);

  contentInner.appendChild(signatureSibling);
  contentInner.appendChild(signatureEl);

  let groupEls = [];
  letterGroups.forEach((group,g) => {
    const groupEl = createGroup();
    contentInner.insertBefore(groupEl,group[0]);
    group.forEach(el => {
      el.parentNode.removeChild(el);
      groupEl.appendChild(el);
    })
    groupEls.push(groupEl);

  })

  if (window.innerWidth > 768) {

    const containerOffset = parseInt(getComputedStyle(galleryColumn).paddingTop);

    groupEls.forEach((g,i) => {
      let topOffset = 0;
      if (groupEls[i-1]) {
        const rect = g.getBoundingClientRect();
        const oRect = groupEls[i-1].getBoundingClientRect();
        topOffset = (oRect.height / 2) - 2 + "px";
      } else {
        topOffset = contentContainer.querySelector('.component__title').offsetHeight;
      }

      gsap.timeline({
        scrollTrigger:{
          trigger: g,
          start: `top-=${topOffset} center`,
          end: `${i == groupEls.length-1 ? 'bottom' : 'center'} center`,
          pin: false,
          scrub: true,
          snap: {
            snapTo:1,
            duration:0,
            delay:0
          },
          anticipatePin:1,
          pinSpacing:true,
          markers:false,
          onEnter: () => {
            removeActiveClasses(container);
            container.classList.add(`active-${i}`);
            if (i === groupEls.length-1) signatures.forEach(s => s.style.visibility = "visible");
          },
          onEnterBack: () => {
            removeActiveClasses(container);
            container.classList.add(`active-${i === 0 ? i : i-1}`);
          }
        }
      })
    })

    ScrollTrigger.create({
      trigger: container,
      pin: gallery,
      start: 'top top',
      end: `bottom ${gallery.offsetHeight * .5 + containerOffset}`,
      anticipatePin: 1,
      pinSpacing: false,
      markers:false
    });

  } else {
    signatures.forEach(s => s.style.visibility = "visible");
  }

})()
